<template>
  <el-card class="box-card">
    <el-page-header
      class="product-header"
      @back="goBack"
      content="修改合同"
    ></el-page-header>
    <div class="select-container">
      <el-form
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        label-position="left"
        :rules="rules"
        v-loading.fullscreen.lock="fullscreenLoading"
      >
        <el-form-item label="合同编号" prop="No">
          <el-input style="width: 30%" v-model="info.No"></el-input>
        </el-form-item>
        <div class="NoStyleSucces" v-show="succesShow">
          <i class="el-icon-check iconStyle"
            >&nbsp;&nbsp;<span style="color: #999">该编号可用</span></i
          >
        </div>
        <div class="NoStyleerror" v-show="errorShow">
          <i class="el-icon-close iconStyle"
            >&nbsp;&nbsp;<span style="color: #999">该编号已被占用</span></i
          >
        </div>
        <el-form-item style="width: 40%" label="会员名" required>
          <el-input v-model="info.enterprice"></el-input>
        </el-form-item>
        <el-form-item label="合同金额" required>
          <el-input-number
            v-model="info.contractMoney"
            :precision="2"
            :step="1"
            :min="0.1"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="服务费" required>
          <el-input-number
            v-model="info.serviceCharge"
            :precision="2"
            :step="1"
            :min="0.1"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="合同额度" required>
          <el-input-number
            v-model="info.contractLimit"
            :precision="2"
            :step="1"
            :min="0.1"
          ></el-input-number>
        </el-form-item>
        <el-form-item style="width: 40%" label="联系电话">
          <el-input
            v-model="info.phoneNo"
            placeholder="请输入联系电话"
          ></el-input>
        </el-form-item>
        <el-form-item label="合同图片">
          <input type="file" :disabled="disabledImage" @change="onchangemd" />
          <div class="files_list">
            <div
              class="files_list_childer"
              v-for="(item, index) in imageInfo"
              :key="index"
            >
              {{ item.imageName }}
              <span @click="files_D(item)" class="files_D el-icon-close"></span>
            </div>
          </div>
          <div class="imgList">
            <div v-for="(item, index) in imageInfo" :key="index">
              <span @click="image_D(item)" class="icon el-icon-error"></span>
              <img :src="item.imageUrl" alt="" />
            </div>
          </div>
        </el-form-item>
        <el-form-item style="width: 40%" label="创建时间">
          <el-input v-model="info.createTime" :disabled="true"> </el-input>
        </el-form-item>
        <el-form-item style="width: 40%" label="上次修改时间">
          <el-input v-model="info.updateTime" :disabled="true"> </el-input>
        </el-form-item>
        <el-form-item style="width: 60%" label="备注">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="info.remark"
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">保存修改</el-button>
          <el-button @click="onCancel">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-card>
</template>
<script>
import axios from "axios";
import moment from "moment";
import secret from "@/utils/secret.js";
export default {
  data() {
    let self = this;
    let NoEvent = function () {
      self.verify();
    };
    return {
      info: "",
      imageInfo: [],
      fullscreenLoading: true,
      succesShow: false,
      errorShow: false,
      disabledImage: false,
      rules: {
        No: [
          {
            required: true,
            message: "请输入合同编号",
            trigger: "blur",
            validator: NoEvent,
          },
          {
            min: 3,
            max: 20,
            message: "长度在 3 到 20 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.userKey = secret.Decrypt(localStorage.getItem("user_key"));
    const contractNo = this.$route.params.id;
    let userKey = this.userKey;
    axios.post(this.GLOBAL.api + "/creat/contract", { contractNo,userKey }).then((res) => {
      this.info = res.data[0];
      this.fullscreenLoading = false;
      this.imageInfo = res.data[0].picture ? JSON.parse(res.data[0].picture) : [];
    });
  },
  methods: {
    image_D(e) {
      this.imageInfo = this.imageInfo.filter((item) => {
        return item.id !== e.id;
      });
    },
    files_D(e) {
      this.imageInfo = this.imageInfo.filter((item) => {
        return item.id !== e.id;
      });
    },
    // update image
    onchangemd(e) {
      this.disabledImage = true;
      const files = e.target.files[0];
      const isLt5M = +files.size > 5242880 ? true : false;
      if (isLt5M) {
        this.$message({
          type: "worning",
          message: "图片大小不允许大于5M！",
        });
        this.disabledImage = false;
      } else if (
        files.type === "image/png" ||
        files.type === "image/jpeg" ||
        files.type === "image/jpg"
      ) {
        let formdata = new FormData();
        formdata.append("uploadFile", files);
        axios({
          method: "post",
          url: this.GLOBAL.file,
          data: formdata,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }).then((res) => {
          this.imageInfo.push({
            id: this.imageInfo.length,
            imageName: files.name,
            imageUrl: res.data,
          });
        });
        this.$message({
          type: "success",
          message: "上传成功",
        });
        this.disabledImage = false;
      } else {
        this.$message({
          type: "worning",
          message: "图片格式不正确",
        });
        this.disabledImage = false;
      }
    },
    verify() {
      let duplicateNo = this.info.No;
      let userKey = this.userKey;
      axios.post(this.GLOBAL.api + "/creat/contract", { duplicateNo, userKey }).then((res) => {
        if (res.data.statusCode === 200 && this.info.No !== "") {
          this.succesShow = true;
          this.errorShow = false;
        } else if (res.data.statusCode === 201) {
          this.errorShow = true;
          this.succesShow = false;
        } else {
          this.$message({
            message: "请检查合同信息",
            type: "warning",
          });
        }
      });
    },
    goBack() {
      this.$router.push("/contract/list");
    },
    onSubmit() {
      this.info.updateTime = moment().format("YYYY-MM-DD HH:mm:ss");
      const ruleFormData = this.info;
      const imageInfo = JSON.stringify(this.imageInfo);
      const fix = true;
      let userKey = this.userKey;
      axios.post(this.GLOBAL.api + "/creat/contract", { fix, ruleFormData, imageInfo, userKey }).then((data) => {
        if (data.data.statusCode == 400) {
          this.$message({
            message: "修改失败，请联系管理员",
            type: "warning",
          });
        } else {
          this.$message({
            message: "合同修改成功",
            type: "success",
          });
          this.$router.push("/contract/list");
        }
      });
    },
    onCancel() {
      this.$confirm("您还未保存修改, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$router.push("/contract/list");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
  },
};
</script>
<style scoped>
.product-header {
  height: 40px;
  background-color: #f9f9f9;
  line-height: 40px;
  padding-left: 1%;
}
.select-container {
  padding: 1% 3%;
  position: relative;
}
.box-card {
  width: 100%;
  height: 100%;
}
.iconStyle {
  color: green;
  font-weight: bold;
  position: absolute;
  top: 2%;
  left: 40%;
}
.imgList {
  display: flex;
  justify-self: flex-start;
}
.imgList div{
  width: 15%;
  margin-right: 15px;
}
.imgList img {
  width: 100%;
}
.icon {
  display: none;
}
.imgList div:hover .icon {
  display: inline-block;
} 
</style>